<template>
  <b-container>
    <b-row align-h="center">
      <b-col md="8">
        <b-card>
          <b-badge
            :variant="statusVariant(this.status)"
            class="mr-1 position-absolute position-right-0"
          >
            <span v-if="this.status === 1">Menunggu Respon</span>
            <span v-else-if="this.status === 2">Dalam Proses</span>
            <span v-else-if="this.status === 3">Ditolak</span>
            <span v-else-if="this.status === 4">Selesai</span>
          </b-badge>
          <b-tabs>
            <b-tab title="Detail">
              <b-form v-on:submit.prevent>
                <b-row>
                  <b-col>
                    <b-form-group label="Nama Pelanggan" label-for="name">
                      <b-form-input id="name" v-model="form.name" readonly />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Tanggal Permintaan" label-for="date">
                      <b-form-input
                        id="name"
                        v-model="form.date"
                        readonly
                      /> </b-form-group
                  ></b-col>
                  <b-col md="6">
                    <b-form-group label="Tanggal Kejadian" label-for="date">
                      <b-form-input
                        id="name"
                        v-model="form.incident_date"
                        readonly
                      /> </b-form-group
                  ></b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      label="Estimasi Jumlah OR"
                      label-for="estimated_amount"
                    >
                      <cleave
                        id="estimated_amount"
                        placeholder="Estimasi Jumlah OR"
                        v-model="form.estimated_or"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group
                      label="Estimasi biaya asuransi (OR)"
                      label-for="estimated_cost"
                    >
                      <cleave
                        id="estimated_cost"
                        placeholder="Estimasi biaya asuransi (OR)"
                        v-model="form.estimated_cost"
                        class="form-control"
                        :raw="false"
                        :options="options.number"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Deskripsi" label-for="description">
                  <b-form-textarea
                    id="description"
                    placeholder="Deskripsi"
                    v-model="form.description"
                    readonly
                  />
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-success"
                      block
                      class="my-1"
                      @click="toWhatsApp()"
                    >
                      Hubungi Pelanggan
                    </b-button>
                  </b-col>
                </b-row>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  v-b-modal.modal-photo
                  class="my-1"
                >
                  View Photo
                </b-button> -->
                <b-modal
                  id="modal-photo"
                  ok-only
                  ok-title="close"
                  centered
                  size="lg"
                  title="Photo"
                  class="text-center"
                >
                  <b-img
                    id="photo"
                    :src="photo"
                    width="1000px"
                    fluid
                    alt="trouble photo"
                  />
                </b-modal>
              </b-form>
            </b-tab>
            <b-tab title="Foto">
              <b-row>
                <b-col md="4" v-for="item in items" :key="item">
                  <b-img
                    id="photo"
                    :src="item"
                    fluid
                    alt="trouble photo"
                    class="mb-2"
                    v-b-modal.modal-photo
                  />
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
      <b-col md="4" v-if="status === 3">
        <b-card>
          <b-form-group label="Reject Reason" label-for="reject_reason">
            <b-form-textarea
              id="reject_reason"
              placeholder="Reject Reason"
              v-model="form.reject_reason"
              disabled
            />
          </b-form-group>
        </b-card>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col md="8" v-if="status === 1">
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="danger"
              class="mr-1"
              block
              v-b-modal.modal-reject
            >
              Tolak
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              class="mr-1"
              block
              @click="process()"
            >
              Proses
            </b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="8" v-if="status === 2">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="success"
          class="mr-1"
          block
          @click="finish()"
        >
          Selesai
        </b-button>
      </b-col>

      <!-- modal change password -->
      <b-modal
        id="modal-reject"
        title="Penolakan Permintaan"
        ok-title="Kirim"
        cancel-variant="outline-secondary"
        centered
        hide-footer
      >
        <validation-observer ref="loginValidation">
          <b-form ref="form" @submit.prevent>
            <b-form-group
              :state="errors"
              label="Alasan Penolakan"
              label-for="reject"
              invalid-feedback="Alasan penolakan tidak boleh kosong"
            >
              <validation-provider
                #default="{ errors }"
                name="reject"
                rules="required"
              >
                <b-form-textarea
                  id="reject"
                  name="reject"
                  type="text"
                  v-model="form.reject_reason"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Alasan Penolakan"
                  required="required"
                />
                <small class="text-danger">{{ errors[0] || errMessage }}</small>
              </validation-provider>
            </b-form-group>

            <b-row>
              <b-col>
                <b-button
                  class="mt-1 mb-2"
                  type="submit"
                  variant="danger"
                  block
                  @click="reject()"
                >
                  Kirim
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-modal>
      <!-- end modal change password -->
    </b-row>
  </b-container>
</template>

<script>
import {
  BFormTextarea,
  BBadge,
  BImg,
  BFormTimepicker,
  BFormDatepicker,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BContainer,
  BTabs,
  BTab,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import flatPickr from "vue-flatpickr-component";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";
import moment from "moment";

export default {
  components: {
    ToastificationContent,
    flatPickr,
    Cleave,
    BFormTextarea,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    BImg,
    BFormTimepicker,
    BFormDatepicker,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    draggable,
    Prism,
    BContainer,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        reject_reason: "",
        location: "",
        latitude: "",
        longitude: "",
        estimated_or: "",
        estimated_cost: "",
        phone: "",
      },
      status: "",
      photo: "",
      permissions: [],
      errors: "",
      errMessage: "",
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      photos: [],
      items: [],
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        1: "light-warning",
        2: "light-primary",
        3: "light-danger",
        4: "light-success",
      };

      return (status) => statusColor[status];
    },
  },
  setup() {
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  created() {
    this.getAllPermissions();
    this.getDetails();
  },
  methods: {
    moment(date) {
      return moment(date).format("DD-MM-YYYY, hh:mm");
    },
    getAllPermissions() {
      this.permissions = sessionStorage.getItem("permissions");
    },
    getDetails() {
      const id = this.$route.params.id;
      const { baseUrl } = $themeConfig.app;
      const imageUri = baseUrl + "image/request/insurance-claim/";

      this.$http
        .get("request/insurance-claim/" + id)
        .then((response) => {
          this.status = response.data.data.status;
          this.form.name = response.data.data.user_name;
          this.form.date = this.moment(response.data.data.created_at);
          this.form.incident_date = response.data.data.date;
          this.photo = imageUri + response.data.data.image;
          this.photos = this.getImages(imageUri, response.data.data.images);
          this.form.description = response.data.data.description;
          this.form.reject_reason = response.data.data.reject_reason;
          this.form.estimated_or = response.data.data.estimated_amount;
          this.form.estimated_cost = response.data.data.estimated_cost;
          this.form.phone = response.data.data.phone;
        })
        .catch((errors) => {
          console.log(errors.response);
        });
    },
    getImages(uri, datas) {
      var i;
      for (i = 0; i < datas.length; i++) {
        this.items.push(uri + datas[i].image);
      }
    },
    process() {
      const id = this.$route.params.id;
      this.$http
        .post("request/insurance-claim/" + id + "/process")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Insurance Claim Request Process",
              variant: "success",
            },
          });
          location.href = "/request/insurance-claims";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    finish() {
      const id = this.$route.params.id;
      this.$http
        .post("request/insurance-claim/" + id + "/finish")
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Insurance Claim Request Finish",
              variant: "success",
            },
          });
          location.href = "/request/insurance-claims";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    reject() {
      const id = this.$route.params.id;
      this.$http
        .post("request/insurance-claim/" + id + "/reject", {
          reject_reason: this.form.reject_reason,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Insurance Claims Request Rejected",
              variant: "danger",
            },
          });
          location.href = "/request/insurance-claims";
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
    toWhatsApp() {
      const phone = this.form.phone;
      var uri = "https://wa.me/62" + phone.substring(1);
      window.open(uri, "_blank", "noreferrer");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>